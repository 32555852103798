
<template>
    <div class="querycommunity">
        <LiefengContent>
            <template v-slot:title>“社区随约服务网上驿站”平台服务数据统计</template>
            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true" class="search">
                    <FormItem label="统计截止时间" :label-width="150">
                        <DatePicker @on-change="changeTime" :value="selectObj.gmtCreate" type="date" style="width: 200px" clearable></DatePicker>
                    </FormItem>
                    <FormItem>
                        <Select v-model="selectObj.onlineType" filterable transfer>
                            <!-- 防止报错，使用了index -->
                            <Option value="">全部社区</Option>
                            <Option value="1">市级示范社区</Option>
                            <Option value="2">区级示范社区</Option>
                        </Select>
                    </FormItem>
                    <Button type="primary" icon="ios-search" @click="getList" style="margin-right: 10px">查询</Button>
                    <Button type="success" icon="ios-refresh" @click="rest" style="margin-right: 10px">重置</Button>
                </Form>
            </template>

            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="columns"
                    :tableData="data"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    showsummary
                    :summaryData="summaryData"
                ></LiefengTable>
            </template>
        </LiefengContent>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: {
        LiefengContent,
        LiefengTable,
    },
    created() {
        this.selectObj.gmtCreate = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
        this.getList()
    },
    data() {
        return {
            selectObj: {
                gmtCreate: "",
                onlineType: "",
            },
            loading: false,
            columns: [
                { align: "center", minWidth: 120, title: "区", key: "zoneName" },
                { align: "center", minWidth: 120, title: "街道", key: "streetName" },
                { align: "center", minWidth: 160, title: "社区", key: "orgName" },
                { align: "center", minWidth: 160, title: "注册用户数 (人）", key: "onlineUser" },
                { align: "center", minWidth: 160, title: "信息发布数（项）", key: "communityInfo" },
                { align: "center", minWidth: 160, title: "居民接受信息服务数据（人次）", key: "communityView" },
                { align: "center", minWidth: 160, title: "居民参与互动服务统计数据（人次）", key: "communityJoin" },
            ],
            data: [],
            page: 1,
            pageSize: 20,
            total: 0,
            summaryData: {},
        }
    },
    methods: {
        changeTime(val) {
            this.selectObj.gmtCreate = val
        },
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        rest() {
            this.selectObj = {}
            this.page = 1
            this.pageSize = 20
            this.getList()
        },
        // 获取数据接口
        getList() {
            this.loading = true
            this.$get("/gateway/api/symanage/pc/orgAudit/selectByPage", {
                statDate: this.$core.formatDate(new Date(this.selectObj.gmtCreate), "yyyy-MM-dd"),
                page: this.page,
                pageSize: this.pageSize,
                onlineType: this.selectObj.onlineType,
            })
                .then(res => {
                    this.loading = false
                    if (res.code == 200 && res.dataList) {
                        this.data = res.dataList
                        this.page = res.currentPage
                        this.total = res.maxCount
                        let onlineUser = 0,communityInfo = 0,communityView = 0,communityJoin = 0
                        this.data.map(item =>{
                            onlineUser = item.onlineUser + onlineUser
                            communityInfo = item.communityInfo + communityInfo
                            communityView = item.communityView + communityView
                            communityJoin = item.communityJoin + communityJoin
                        })
                        var sums = {}
                        sums["zoneName"] = {
                            key: "zoneName",
                            value: "合计",
                        }
                        sums["streetName"] = {
                            key: "streetName",
                            value: "",
                        }
                        sums["orgName"] = {
                            key: "orgName",
                            value: "",
                        }
                        sums["onlineUser"] = {
                            key: "onlineUser",
                            value: onlineUser,
                        }

                        sums["communityInfo"] = {
                            key: "communityInfo",
                            value: communityInfo,
                        }
                        sums["communityView"] = {
                            key: "communityView",
                            value: communityView,
                        }
                        sums["communityJoin"] = {
                            key: "communityJoin",
                            value: communityJoin,
                        }

                        this.summaryData = sums
                    } else {
                        this.$Message.error({
                            content: "数据获取失败",
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.error({
                        content: "数据获取失败",
                        background: true,
                    })
                })
        },
    },
}
</script>

<style scoped lang="less">
.querycommunity {
    /deep/ .table > .ivu-table > .ivu-table-tip > table > tbody > tr > td {
        height: calc(100vh - 150px);
        border-left: 1px solid #f8f8f9;
        border-bottom: 1px solid #f8f8f9;
        border-right: 1px solid #f8f8f9;
    }
}
</style>